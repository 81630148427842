import * as React from 'react';
import { graphql } from 'gatsby';
import Seo from '@/components/Seo';
import Layout from '@/layouts/default';
import BlockContent from '@/components/BlockContent';
import BlockContentContainer from '@/components/BlockContentContainer';
import { CoverProps } from '@/components/Cover';

export interface PageTemplateProps {
  pageContext: {
    language: string;
    languageLinks: LanguageLinks;
  };
  data: {
    article: Pick<GatsbyTypes.SanityArticle, 'title' | '_rawBody'> & {
      readonly meta: Pick<GatsbyTypes.SanityMeta, 'title' | 'description'>;
      readonly mainMedia: CoverProps['media'];
    };
  };
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  pageContext: { languageLinks },
  data: { article },
}) => {
  return (
    <Layout languageLinks={languageLinks}>
      <Seo
        title={article.meta!.title!}
        description={article.meta!.description}
        image={article!.mainMedia}
      />
      <BlockContentContainer>
        <BlockContent blocks={article?._rawBody ?? []} />
      </BlockContentContainer>
    </Layout>
  );
};

export default PageTemplate;

export const query = graphql`
  query ArticlePage($id: String!) {
    article: sanityArticle(id: { eq: $id }) {
      title
      meta {
        title
        description
      }
      mainMedia {
        ...SanityCoverFields
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
  }
`;
